import React from "react"
import styled from 'styled-components';
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  InnerContainer,
  Spacer,
  Column,
  Grid,
} from 'components/ui';
import sc from 'config/theme';
import $ from 'jquery';
import { Script, Sans, Serif } from 'Typography';
import bp from 'config/responsive';

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 60px;
  padding-bottom: 60px;


  ${bp.computer} {
    padding-top: 140px;
    padding-bottom: 100px;
    min-height: 70vh;
  }

  & h2 {
    color: #0B1D3F;
    margin: 30px 0;
  }

  & ul.bold ,
  & b, strong {
    font-weight: bold;
  }

  & ul ul {
    margin: 15px 0;
  }

  & .table{
    display: table;
    width: 100%;
    max-width: 800px;
    margin: 40px 0;

    .table-row {
      display: table-row;

      &:last-child {
        .table-col {
          border-bottom: 1px solid #cecece;
        }
      }

      .table-col {
        display: table-cell;
        padding: 5px 10px;
        border-left: 1px solid #cecece;
        border-top: 1px solid #cecece;
        width: 33%;
        vertical-align: middle;

        &:last-child {
          border-right: 1px solid #cecece;
        }

        p {
          margin-bottom: 10px;
        }
      }

      &.row-header {
        font-weight: bold;
        color: #0a1e3f;
        background: #ececec;
      }
    }
  }

`

const StyledGrid = styled(Grid)`
  &&& {
    padding-top: 50px;
    margin-bottom: 30px;

    ${bp.tablet} {
      margin-bottom: 100px;
    }

    ${bp.computer} {
      padding-top: 0;
      margin-bottom: 100px;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${bp.computer} {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  display: block;

`;




const TermsPage = ({ data }) => {

 const { pages: {  tos } } = data;
 const {seo} = tos;


 return (
  <Layout>
        <SEO title={seo.title} description={seo.meta} keywords={seo.keywords} />

    <Container>
      <InnerContainer>
        <StyledGrid>
          <Column width={1}></Column>
          <Column width={10}>
            <div>
              <Title class="">
                <Serif alpha color="main" block>{tos.title}</Serif>
              </Title>
              <Sans alpha color="gray">
              <div dangerouslySetInnerHTML={{__html: tos.content}} />

              </Sans>
            </div>
          </Column>
          <Column width={1}></Column>
        </StyledGrid>

      </InnerContainer>
    </Container>

  </Layout>
  );
};

export const query = graphql`
  query TermsPage {
    pages {
      tos {
        title
        content
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;
export default TermsPage
